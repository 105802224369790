@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

/* Global vars */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-yellow: #f1a71c;
  --color-white: #fff;
  --color-error: rgb(255, 13, 13);
  --color-background: #383185;
}

html,
body {
  width: 100%;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  background-color: var(--color-background);
  font-size: 20px;
  padding-bottom: 25px;
}

html {
  position: absolute;
}

body {
  overflow: scroll;
  color: #fff;
  overflow-x: hidden;
}

section {
  padding: 0 10px;
}

ul {
  list-style: none;
}

a {
  all: unset;
  cursor: pointer;
}

button {
  all: unset;
  cursor: pointer;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col {
  flex-direction: column;
}

.rub {
  font-family: 'Rubik', sans-serif;
}

.click {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

/* Header */

.header {
  width: 100%;
}

.activeLink {
  color: #236cbb;
}

.activeLink:hover,
.activeLink:visited,
.activeLink:active {
  color: var(--color-yellow);
}

.pageLink {
  color: #b0b0b0;
}

.pageLink:hover {
  color: #e6e6e6;
}

#pagesList {
  gap: 30px;
  padding: 10px 0;
}

/* Home */

.home-container,
.reset-container,
.change-container {
  margin-top: 15px;
  gap: 25px;
  max-width: 900px;
}

.title {
  font-size: 30px;
  color: #f1a71c;
}

.active-form-container {
  flex-wrap: wrap;
  gap: 30px;
}

.word-form {
  gap: 7px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 3px 3px 2px 0 rgba(0, 0, 0, 0.65);
  color: #000;
  width: 230px;
  height: 150px;
}

.goodTry {
  border: solid 1px rgb(13, 175, 35);
}

.wordTitle {
  font-size: 23px;
  color: #000;
}

.form-input-container {
  gap: 10px;
}

.word-form input {
  padding: 3px 0;
  text-align: center;
  border: solid 1px #f1a71c;
  outline: none;
  font-family: 'Ubuntu', sans-serif;
  border-radius: 6px;
}

.word-form button {
  padding: 5px 10px;
  background-color: #fff;
  border: solid 1px #f1a71c;
  border-radius: 6px;
  font-size: 17px;
}

.word-form.tried,
.word-form button.tried,
.word-form input.tried {
  border: solid 2px red;
}

.word-form button:hover {
  background-color: #ffdea0;
}

.correctText {
  color: rgb(13, 175, 35);
}

/* Completed */

.completed-container {
  margin-top: 15px;
  gap: 20px;
  width: 80%;
  align-items: center;
  max-width: 600px;
}


.completedWords {
  gap: 20px;
}

.completedWords > p {
  padding: 10px 0;
}

.completedWord {
  color: #f1a71c;
}

.completedWordContainer {
  gap: 5px;
}

.possibleWord {
  color: #ffdb99;
}

.finalMsg {
  margin: 10px 0 20px 0;
  color: #f1a71c;
}

/* About page */

.about-container {
  margin-top: 15px;
  gap: 20px;
  width: 80%;
  align-items: center;
  font-size: 18px;
  max-width: 600px;
}

.myLink,
.myLink:visited,
.myLink:active,
.myLink:focus {
  color: #f1a71c;
}

.myLink:hover {
  color: #ffdea0;
}

.appName {
  color: #f1a71c;
}

.versionText,
.small {
  font-size: 13px;
}

/* Not found */

.notFound {
  font-size: 100px;
}

.not-found-container {
  margin-top: 15px;
  gap: 30px;
}

.text-link {
  color: var(--color-yellow);
}

/* login page */

.login-container {
  margin-top: 15px;
  gap: 20px;
  width: 80%;
  align-items: center;
  font-size: 18px;
  max-width: 600px;
}

.login-form {
  gap: 15px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 3px 3px 2px 0 rgba(0, 0, 0, 0.65);
  color: #000;
  width: 100%;
  max-width: 350px;
}

.login-form label {
  gap: 5px;
}

.login-form input {
  padding: 8px 15px;
  text-align: center;
  border: solid 1px #f1a71c;
  outline: none;
  font-family: 'Ubuntu', sans-serif;
  border-radius: 6px;
}

.login-form input.login-form-btn {
  padding: 5px 15px;
  background-color: #fff;
  border: solid 1px #f1a71c;
  border-radius: 6px;
  font-size: 17px;
  color: black;
}

.login-form input.login-form-btn:hover {
  background-color: #ffdea0;
}

.login-signup-text {
  gap: 5px;
}

.form-error-text {
  color: var(--color-error);
  font-size: 14px;
}

.yellow {
  color: var(--color-yellow);
}

.none {
  display: none;
}

/* navbar mobile */

.navBar {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.burger-icon {
  position: relative;
  font-size: 30px;
  background-color: var(--color-background);
  color: var(--color-yellow);
  z-index: 0;
  text-align: left;
  overflow-x: hidden;
  width: 15%;
  flex-grow: 1;
}

/* volume icon */

.volume-icon {
  position: relative;
  margin: auto;
  font-size: 25px;
  cursor: pointer;
  justify-self: flex-start;
  width: 15%;
  flex-grow: 1;
}

.links-container.mobile {
  position: fixed;
  color: black;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  background-color: white;
  z-index: 0;
  transition: right ease 0.2s;
  top: 0;
}

.links-container.mobile.hide {
  right: -600px;
}

.links-container.mobile.show {
  right: 0;
}

.footer {
  padding: 20px 0;
}

.mute-sound-text {
  font-size: 15px;
  color: var(--color-yellow);
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .header {
    justify-content: space-between;
    min-height: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0 1px 15px;
    padding: 5px 20px;
  }

  section {
    padding-top: 10px;
  }

  .navBar {
    width: 100%;
    justify-content: space-between;
  }

  .pageLink {
    color: black;
  }

  .pageLink:hover {
    color: darkgray;
  }
}

@media screen and (min-width: 601px) {
  .volume-icon {
    position: fixed;
    align-self: flex-start;
    top: 10px;
    left: 10px;
    font-size: 25px;
    cursor: pointer;
    justify-self: flex-start;
    flex-grow: 1;
  }
}

/* reset page */

.reset-notification-container {
  max-width: 300px;
}

.recover-form-email {
  width: 300px;
}

.reset-notification {
  font-size: 14px;
  color: var(--color-yellow);
}

.reset-form {
  width: 80%;
}
